import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-lexique',
  templateUrl: './lexique.component.html',
  styleUrls: ['./lexique.component.scss'],
})
export class LexiqueComponent implements OnInit {
  mots = [];

  constructor(public afs: AngularFirestore) {}

  ngOnInit(): void {
    const collection = this.afs.collection('lexique');
    collection
      .get()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          this.mots.push(element.data());
          console.log(this.mots);
        });
      });
  }
}
