<header>
    <div class="collapse bg-dark" id="navbarHeader">
        <div class="container">
            <div class="row">
                <div class="col-sm-7 col-md-7 py-4">
                    <h4 class="text-white">A propos</h4>
                    <p class="text-muted">Ce site a pour simple but de retranscrire les micro chroniques ayant lieues à
                        l'écrit
                        lors de la team matin sur le chat de <a href="https://twitch.tv/ponce">ponce</a>
                        &nbsp; <img src="assets/images/poncefleur.png" width="24px" height="24px"
                            alt="icone ponceFLEUR">
                    </p>
                </div>
                <div class="col-sm-5 py-4">
                    <h4 class="text-white">Créations de la team</h4>
                    <ul class="list-unstyled">
                        <li><a [routerLink]="['lexique']" class="text-white">Mots de l'étalage de
                                confiture, By
                                Braikfruit</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar navbar-dark bg-dark box-shadow">
        <div class="container d-flex justify-content-between">
            <a [routerLink]="['home']" class="navbar-brand d-flex align-items-center">
                <img src="assets/images/ponceradio.jpg" width="20px" height="20px" alt="" srcset="">
                &nbsp;
                <strong>Pré-coce Radio</strong>
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader"
                aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
    </div>
</header>