<div class="container">
    <table class="table">
        <thead>
            <tr>
                <th>Mot</th>
                <th>Groupe grammatical</th>
                <th>Definition</th>
            </tr>
        </thead>
        <tbody *ngIf="mots && mots.length > 0">
            <tr *ngFor="let mot of mots">
                <!-- opening modal
             <th scope="row" data-toggle="modal" data-target="#modalRecapRadio">
            {{ chronique.heure }}
          </th> -->
                <th scope="row">
                    {{ mot.mot }}
                </th>
                <td>{{mot.groupe}}</td>
                <td>{{mot.definition}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="mots == null ||  mots.length == 0">
            <tr>
                <th scope="row">No data for this day</th>
                <th>No data for this day</th>
                <th>No data for this day</th>
            </tr>
        </tbody>
    </table>
</div>