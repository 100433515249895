import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  dateToShow = new Date();
  dataToShow;

  constructor(public afs: AngularFirestore) {}

  ngOnInit(): void {
    this.showData();
  }

  showData(): void {
    const docRef = this.afs.doc(
      `emissions/${this.dateToShow.getDate()}-${
        this.dateToShow.getMonth() + 1
      }-${this.dateToShow.getFullYear()}`
    );
    docRef.get().subscribe((doc) => {
      this.dataToShow = doc.data();
    });
  }

  precedentDay(): void {
    this.incrementDay(-1);
    this.showData();
  }

  nextDay(): void {
    this.incrementDay(1);
    this.showData();
  }

  private incrementDay(delta: number): void {
    this.dateToShow = new Date(
      this.dateToShow.getFullYear(),
      this.dateToShow.getMonth(),
      this.dateToShow.getDate() + delta
    );
  }
}
