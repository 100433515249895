<div class="container">
    <div class="row justify-content-center">
        <div class="col-sm-12 text-center">
            <p>
                <button type="button" class="btn btn-default" aria-label="Precedent Day" (click)="precedentDay()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    </svg>
                </button>


                <span class="vertical-center">{{ dateToShow | date: 'fullDate':'':'fr'}}</span>

                <button type="button" class="btn btn-default" aria-label="Next Day" (click)="nextDay()">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </button>
            </p>
        </div>
    </div>
    <table class="table">
        <thead>
            <tr>
                <th>Heure</th>
                <th>Chroniqueur</th>
                <th>Chronique</th>
                <th>Contenu</th>
            </tr>
        </thead>
        <tbody *ngIf="dataToShow && dataToShow.chroniques.length > 0">
            <tr *ngFor="let chronique of dataToShow.chroniques">
                <th scope="row">
                    {{ chronique.heure }}
                </th>
                <td>{{chronique.chroniqueur}}</td>
                <td>{{chronique.chroniqueName}}</td>
                <td [innerHTML]="chronique.contenu"> </td>
            </tr>
        </tbody>
        <tbody *ngIf="dataToShow == null || dataToShow.chroniques.null || dataToShow.chroniques.length == 0">
            <tr>
                <th scope="row">No data for this day</th>
                <th>No data for this day</th>
                <th>No data for this day</th>
            </tr>
        </tbody>
    </table>
</div>

<!-- Modal -->
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="modalRecapRadio"
    aria-labelledby="modalRecapRadioLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalRecapRadioLabel">Récap de l'émission du {{ this.dataToShow }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>