// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCnraaYLLmu8CKgTUm4aZK3JiPX3Uw0dJw',
    authDomain: 'precoce-radio.firebaseapp.com',
    databaseURL: 'https://precoce-radio.firebaseio.com',
    projectId: 'precoce-radio',
    storageBucket: 'precoce-radio.appspot.com',
    messagingSenderId: '1063766600029',
    appId: '1:1063766600029:web:6bda9f5861b16a3fa960b4',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
